










































































import API from '@/plugins/axios';
import Vue from 'vue';
import { imageData } from '@/utils/utilsData';

export default Vue.extend({
  name: 'Skills',
  data: () => ({
    imageData: { ...imageData },
    searchSkill: '',
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    selected: [] as any[],
    selectedCount: -1,
    selectedPage: 1,
    selectedLoading: true,
  }),
  methods: {
    goNext() {
      if (!this.searchSkill) return;
      localStorage.removeItem('search_term');
      localStorage.removeItem('selectedInstitutes');
      localStorage.removeItem('selectedLevels');
      localStorage.removeItem('skillsKits');
      this.$router.push({
        path: '/filters',
        query: {
          search: this.searchSkill,
        },
      });
    },
    async getSelected() {
      this.selectedLoading = true;
      const { data } = await API.get(`/search/select/?page=${this.selectedPage}`);
      this.selected = [...this.selected, ...data.results];
      this.selectedCount = data.count;
      this.selectedPage++;
      this.selectedLoading = false;
    },
    async removeSelected(id: string) {
      await API.delete(`/search/select/${id}/`);
      this.selected = [...this.selected.filter((skill) => skill.id !== id)];
      this.selectedCount--;
    },
  },
  mounted() {
    localStorage.removeItem('search_term');
    localStorage.removeItem('selectedInstitutes');
    localStorage.removeItem('selectedLevels');
    localStorage.removeItem('skillsKits');
    this.getSelected();
  },
});
